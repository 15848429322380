.VideoPlayer {
  position: relative;
  padding-bottom: 56.25%;
  max-width: 100%;
  width: 100%;
}

.VideoPlayer__placeholder {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.VideoPlayer__placeholder:hover .VideoPlayer__play-icon {
  -webkit-transform: scale(.9);
          transform: scale(.9);
}

.VideoPlayer__play-icon {
  -webkit-transition: -webkit-transform .4s cubic-bezier(.75, -.5, 0, 1.45);
  transition: -webkit-transform .4s cubic-bezier(.75, -.5, 0, 1.45);
  transition: transform .4s cubic-bezier(.75, -.5, 0, 1.45);
  transition: transform .4s cubic-bezier(.75, -.5, 0, 1.45), -webkit-transform .4s cubic-bezier(.75, -.5, 0, 1.45);
}

.VideoPlayer__placeholder,
.VideoPlayer__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  z-index: 999;
}

.VideoPlayerModal {
  position: absolute;
  top: 50%;
  left: -120%;
  width: 96%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  opacity: 0;
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear;
}

.VideoPlayerModal--open {
  left: 50%;
  opacity: 1;
  z-index: 99;
}

.VideoPlayerModal__video-holder {
  position: relative;
  padding-bottom: 56.25%;
  height: 100%;
  max-width: 100%;
  width: 100%;
  background-color: #eae5ec;
}

.VideoPlayerModal__video-holder iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  z-index: 999;
}

